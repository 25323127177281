import React from 'react';
import { getAllMediaByRelation, Image } from '@alterpage/gatsby-plugin-image';

import {
    grid,
    textOne,
    textTwo,
    textThree,
    imageOne,
    imageCombined,
    imageFour,
} from './product-grid-description.module.scss';
import { IProduct } from '../../models/product.model';
import { relations } from '../../config/relations';

import Markdown from '../hoc/markdown';

interface IProductGridDescriptionProps {
    className?: string;
    product: IProduct;
}

const ProductGridDescription: React.FC<IProductGridDescriptionProps> = ({ className, product }) => {
    return (
        <div className={[grid, className].join(' ')}>
            <Markdown className={textOne}>{product.description}</Markdown>
            <Image
                className={imageOne}
                relation={relations.gridDescriptionImage1}
                media={getAllMediaByRelation(product.media, relations.gridDescriptionImage1)}
            />
            <div className={imageCombined}>
                <Image
                    relation={relations.gridDescriptionImage2}
                    media={getAllMediaByRelation(product.media, relations.gridDescriptionImage2)}
                />
                <Image
                    relation={relations.gridDescriptionImage3}
                    media={getAllMediaByRelation(product.media, relations.gridDescriptionImage3)}
                />
            </div>
            <Image
                className={imageFour}
                relation={relations.gridDescriptionImage4}
                media={getAllMediaByRelation(product.media, relations.gridDescriptionImage4)}
            />
            <Markdown className={textTwo}>{product.description2}</Markdown>
            <Markdown className={textThree}>{product.description3}</Markdown>
        </div>
    );
};

export default ProductGridDescription;
